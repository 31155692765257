// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-category-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-pages-404-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-cart-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-contact-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-work-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/custom-work.js" /* webpackChunkName: "component---src-pages-custom-work-js" */),
  "component---src-pages-faq-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-index-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/order/index.js" /* webpackChunkName: "component---src-pages-order-index-js" */),
  "component---src-pages-shop-js": () => import("/Users/mattmadeiro/Work/610-design-studio/src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */)
}

exports.data = () => import("/Users/mattmadeiro/Work/610-design-studio/.cache/data.json")

